import React, { useRef } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'

// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`


const features = `
    <section id="features" class="features section">
      <div class="container"  style="text-align: center;">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h3 >Features</h3>
              <h2>
                Switching Provider Made Simple
              </h2>
              <p >
                Introducing the award winning mobile app, that's changing the game.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12 col-11 p-2">
            <div class="m-3 item-feature py-2 px-4">
                <i class="fa-solid fa-calculator fa-2xl m-4 "></i>
                <h3>Calculator </h3>
                <p>
                Stay mindful of the amount of electricity your appliances 
                consume, with the help of an appliance calculator
                </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-11 p-2">
            <div class="m-3 item-feature p-4">
              <i class="fa-solid fa-robot fa-2xl m-4"></i>
              <h3>Artificial intelligence</h3>
              <p>
                Speak to our chatbot- Buster who is powered by OpenAI, you can be sure Buster will have you money on your bills 
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-11 p-2">
            <div class="m-3 item-feature p-4" >
              <i class="fa-solid fa-terminal fa-2xl m-4"></i>
              <h3>Machine Learning</h3>
              <p>
              Worried about next months bill? Predict next months usage with the help of our advanced technology
              </p>
            </div>
          </div>
      </div>
          
    </section>
` 
function openWindow() {
  const newWindow = window.open('https://finalpwa.netlify.app/', 'newWindow', 'width=400,height=850');
  if (newWindow) newWindow.focus();
}
export default function App() {

  
  
 
   
      <div>
        <button onClick={openWindow}>Open Window</button>
      </div>
   


  const parallax = useRef<IParallax>(null!)
  return (
    <div style={{ width: '100%', height: '100%',background:'#e71e70' }}>
      <Parallax ref={parallax} pages={4}>
        <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#D2D6DC' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#D2D6DC' }} />

        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />
         <ParallaxLayer
          offset={3}
          speed={1}
          factor={4}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />
        

      

        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          <img src="img/lightning-01.png" style={{ display: 'block', width: '10%', marginLeft: '55%' }} />
          <img src="img/lightning-01.png" style={{ display: 'block', width: '5%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img src="img/lightning-01.png" style={{ display: 'block', width: '10%', marginLeft: '70%' }} />
          <img src="img/lightning-01.png" style={{ display: 'block', width: '10%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
          <img src="img/lightning-01.png" style={{ display: 'block', width: '5%', marginLeft: '10%' }} />
          <img src="img/lightning-01.png" style={{ display: 'block', width: '10%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
          <img src="img/lightning-01.png" alt="#" style={{ display: 'block', width: '10%', marginLeft: '60%' }} />
          <img src="img/lightning-01.png" alt="#" style={{ display: 'block', width: '10%', marginLeft: '30%' }} />
          <img src="img/lightning-01.png" alt="#" style={{ display: 'block', width: '5%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          <img src="img/lightning-01.png" alt="#" style={{ display: 'block', width: '10%', marginLeft: '5%' }} />
          <img src="img/lightning-01.png" alt="#" style={{ display: 'block', width: '5%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={3.4}
          speed={-0.4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}>
          <img src="img/small-logo.png" style={{ width: '60%', minWidth:'600px'}} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0.3}
          
        />

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img src="https://i.postimg.cc/JngLGChs/bill-Buster-logo.png" alt="Build Buster Logo" style={{ width: '30%',minWidth:'600px' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(2)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', 
          }}>
          <img src="img/phone.png" alt="#" style={{ width: '60%',minWidth:'80%' }} />
        </ParallaxLayer>

        <ParallaxLayer
         offset={2}
         speed={0.1}
         onClick={() => parallax.current.scrollTo(3)}
         style={{ display: 'flex',
         alignItems: 'center',
         justifyContent: 'center'
        }}>
          <div dangerouslySetInnerHTML={{ __html: features }}></div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={-0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(0)}>
          
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize:'1em'}}>
    <a href="https://expo.dev/artifacts/eas/ecdDkdApECSVX4qbrQXJP9.apk" style={{
        textDecoration:'none',
        color:'#fff',  
        backgroundColor:'#29ad56',
        display:'flex',
        flexDirection: 'column',
        width:'100%',
        height:'100%',
        textAlign:'center',
        borderRadius:'20px',
        alignItems:'center',
        fontSize:'2em',
        fontWeight:'bold',
        boxShadow:'3px 5px 25px 2px #333',
        justifyContent: 'center', 
        textAlignLast: 'center',   
        marginBottom: '20px',
        padding: '20px'

    }}>
        <h1 style={{textAlignLast: 'center', justifyContent: 'center', fontSize:'1em'}}>DOWNLOAD</h1>
    </a>

    

    < button onClick={openWindow} style={{
        textDecoration:'none',
        color:'#fff',  
        backgroundColor:'#29ad56',
        display:'flex',
        flexDirection: 'column',
        width:'100%',
        height:'100%',
        textAlign:'center',
        borderRadius:'20px',
        alignItems:'center',
        fontSize:'2em',
        fontWeight:'bold',
        boxShadow:'3px 5px 25px 2px #333',
        justifyContent: 'center', 
        textAlignLast: 'center',   
        padding: '20px'
    }}>
        <h1 style={{textAlignLast: 'center', justifyContent: 'center'}}>WEB APP</h1>
    </button>
</div>
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}
